import React, { Component } from 'react';
import Auth from './Auth'
import Config from '../Config';
import moment from 'moment'

export default class Login extends Component{
    constructor(){
        super()

        this.auth = new Auth();
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            companySetup: {
                companyName:'', address:'', phone:'', email:'', autologout:5, logo:'', mobileApi:'',
                curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            frmLogin:{email: "", pwd: "", role: 0}
        }
    }

    componentDidMount(){
        this.getReportData()
    }

    getReportData = () =>{
        this.$http.get('admin/wapp/setup')
        .then(res => {
            let companySetup = this.auth.decryptData(res.data)
            
            this.setState({ companySetup })
        }).catch((error)=>{console.log('Login: ', error)})
    }

    handleChange = (e) => {
        let frmLogin = this.state.frmLogin
        frmLogin[e.target.name] = e.target.value
        this.setState({ frmLogin })
    }

    doLogin = (e) => {
        e.preventDefault();

        this.auth.login(this.state.frmLogin);
    }

    render(){
        return(
            <div style={{background:'url(assets/img/bg.png) fixed center center repeat',backgroundSize: 'cover', height:'100vh',overflow:'hidden'}}>
                <div id="main" role="main">
                    <div id="content"  className="container ">
                        <div className = "row" >
                            <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4 col-sm-offset-4 col-md-offset-3 col-lg-offset-3">
                                <div style={{'height':'100vh',position:'relative'}}>
                                    <div className="vertical-center">
                                        <div className="text-center"> 
                                            <img src={this.config.ImagePath('custfiles') + '/' + this.state.companySetup.logo} alt={this.state.companySetup.companyName} style={{width:'80px'}} /> 
                                        </div>
                                        <br />
                                        <div className="well no-padding">
                                            <form id="login-form" className="smart-form client-form" onSubmit={this.doLogin}>
                                                <header>Enter Your Credentials </header>
                                                <fieldset>
                                                    <section>
                                                        <label className="label">Username / E-mail</label>
                                                        <label className="input"> <i className="icon-append fa fa-user"></i>
                                                            <input type="text" name="email" 
                                                            value={this.state.frmLogin.email} onChange={this.handleChange} />
                                                            <b className="tooltip tooltip-top-right"><i className="fa fa-user txt-color-teal"></i> Please enter email address/username</b></label>
                                                    </section>

                                                    <section>
                                                        <label className="label">Password</label>
                                                        <label className="input"> <i className="icon-append fa fa-lock"></i>
                                                            <input type="password" name="pwd" 
                                                            value={this.state.frmLogin.pwd}  onChange={this.handleChange} />
                                                            <b className="tooltip tooltip-top-right"><i className="fa fa-lock txt-color-teal"></i> Enter your password</b> </label>
                                                        <div className="note">
                                                            <a href="#">Forgot password?</a>
                                                        </div>
                                                    </section>
                                                </fieldset>
                                                <footer>
                                                    <button type="submit" className="btn btn-primary">Sign in</button>
                                                </footer>
                                            </form>
                                        </div>
                                        <div className="text-center" style={{color:"white",textShadow: "2px 2px 4px #000000"}}>{this.state.companySetup.companyName} &copy; 2017-{moment().format('YYYY')}</div>
                                        <div className="text-center" style={{color:"white",textShadow: "2px 2px 4px #000000"}}>Powered by <a href="http://www.mojaveconsultant.com" target="_blank">Mojave</a></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}